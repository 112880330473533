import React from "react";
import Container from "../../container/Container";
import { Row, Col } from "reactstrap";
import classNames from "classnames";
import "./styles.css";

const Heading = ({
  title,
  big,
  children,
}) => {
  return (
    <>
      <header className={classNames("blog-header", {
        'big': big,
      })}>
        <Container size="small">
          <Row>
            <Col xl="12">
              <div className="title">{ title }</div>
            </Col>
          </Row>
          {children}
        </Container>
      </header>
    </>
  );
};

export default Heading;
